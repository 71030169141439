.scorechart-root {
    flex-grow: 1;
    padding-left:10px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    overflow:hidden;
    flex-wrap:wrap;
}

.scorechart-container {
    display: flex;
    height:60px;
    flex-direction:column;
    justify-content: flex-start;
    align-items: flex-start;
}

.scorechart-label {
    width:60px;
    font-size: 12px;
    color: dimgrey;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}


.scorechart-slider {
    width:200px;
}