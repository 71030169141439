.areacard-root {
    background-color: white;
    -webkit-box-shadow: 5px -5px 15px 5px rgba(207,207,207,0.5);
    box-shadow: 5px -5px 15px 5px rgba(207,207,207,0.5);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    overflow-y:scroll;
    padding:10px;
    height:95vh;
}

.areacard-flag {
    border-bottom:1px solid lightgrey;
    display: flex;
    flex-direction: row;
}

.areacard-rating {
    padding-top: 10px;
    border-bottom:1px solid lightgrey;
}

.areacard-tag {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    flex-wrap:wrap;
    margin-bottom: 10px;
    padding-bottom: 10px;
    border-bottom:1px solid lightgrey;
}

.areacard-tag-showmore {
    margin-right: 10px;
    margin-bottom: 10px;
    color: green
}

.areacard-tag-showmore:hover {
    cursor: pointer;
}

.areacard-review {
    border-bottom:1px solid lightgrey;
    margin-bottom: 10px;
    padding-bottom: 10px;
}

.areacard-comment {
    margin-bottom:40px;
}

