.blog-root {
    flex-grow: 1;
    width: 100vw;
    min-height: 100vh;
    background-color: rgba(255, 192, 0, 0.15);
    padding: 0;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    overflow: hidden;
}

.blog-main {
    flex-grow: 1;
    padding-top:1vh;
    padding-left:1vw;
    padding-right: 1vw;
    overflow:auto;
    -ms-overflow-style: none;
    scrollbar-width: none;
}
