.search-result-root {
    flex-grow: 1;
    width: 100vw;
    height: 100vh;
    background-color: rgba(255, 192, 0, 0.15);
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    overflow: hidden;
}

.search-result-root-mobile {
    flex-grow: 1;
    width: 100vw;
    height: 100vh;
    background-color: rgba(255, 192, 0, 0.15);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    overflow: hidden;
}


.search-result-main {
    background-color: white;
    -webkit-box-shadow: 5px -5px 15px 5px rgba(207,207,207,0.5);
    box-shadow: 5px -5px 15px 5px rgba(207,207,207,0.5);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width:400px;
    overflow:hidden;
}



.search-result-searchbox {
    background-color: white;
    border:1px solid #e6e6e6;
    border-radius: 5px;
    margin: 5px;
    width:90%;
    -webkit-box-shadow: 0px 1px 1px 0px rgba(207,207,207,0.8);
    box-shadow: 0px 1px 1px 0px rgba(207,207,207,0.8);
}

.search-result-searchbox-mobile {
    position:fixed;
    top:0;
    z-index: 4;
    background-color: white;
    border:1px solid #e6e6e6;
    border-radius: 5px;
    margin: 5px;
    width:90%;
    -webkit-box-shadow: 0px 1px 1px 0px rgba(207,207,207,0.8);
    box-shadow: 0px 1px 1px 0px rgba(207,207,207,0.8);
}

.search-result-resultlist {
     overflow:scroll;
     padding-left: 10px;
 }

.search-result-resultlist-mobile {
    position:fixed;
    bottom:45px;
    z-index: 3;
}

.search-result-body {
    position: relative;
}


.search-result-area {
    width:400px;
    overflow:scroll;
    margin-left: 2px;
}

.search-result-area-mobile {
    position: fixed;
    width:100vw;
    overflow:scroll;
    z-index: 5;
}

.search-result-factors {
    padding:5px;
    width:800px;
}

.search-result-factors-mobile{
    position:fixed;
    top:50px;
    z-index: 3;
    padding: 3px;
}


.search-result-map {
    position: absolute;
    overflow: hidden;
    z-index: 1;
    height: 100vh;
    width:80vw;

}


.search-result-map-mobile {
    position: fixed;
    bottom:0;
    overflow: hidden;
    z-index: 1;
    height: 100vh;
    width:100vw;

}