.app-root {
    flex-grow: 1;
    width:100vw;
    height: 100vh;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    overflow:hidden;
}
.app-menu {
    background-color: white;
    -webkit-box-shadow: 5px -5px 15px 5px rgba(207,207,207,0.5);
    box-shadow: 5px -5px 15px 5px rgba(207,207,207,0.5);
    display: flex;
    justify-content: flex-end;
    min-width:290px;
    overflow:hidden;

}

.app-root-mobile {
    flex-grow: 1;
    width:100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    overflow:hidden;
}

.app-menu-mobile {
    background-color: white;
    min-width:100vw;
    -webkit-box-shadow: 5px 0px 15px 0px rgba(207,207,207,0.8);
    box-shadow: 5px 0px 15px 0px rgba(207,207,207,0.8);
    display: flex;
    flex-direction: row;
    justify-content: center;
    overflow:auto;
    position: fixed;
    bottom:0px;
    min-height: 50px;
    z-index: 99;


}
