.district-root {
    flex-grow: 1;
    width:100vw;
    background-color:rgba(100, 181, 246,0.15);
    padding:0;
    overflow:auto;
    -ms-overflow-style: none;
    scrollbar-width: none;
}



.district-content{
    overflow:hidden;
}







