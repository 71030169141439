.cardcontainer-root {
    background-color:white;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    overflow:hidden;
    padding:5px;
    margin-top: 10px;
    margin-bottom: 10px;


 }

.cardcontainer-root:hover {
    cursor: pointer;
}