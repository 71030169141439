.landing-root {
    width:100vw;
    background-color:rgba(217,230,212,0.3);
    padding-top:1vh;
    padding-left:1vw;
    overflow:auto;
    -ms-overflow-style: none;
    scrollbar-width: none;
}

.landing-root::-webkit-scrollbar {
    display: none;
}

.landing-main {
    flex-grow: 1;
    overflow:auto;
    -ms-overflow-style: none;
    scrollbar-width: none;
}

.landing-main::-webkit-scrollbar {
    display: none;
}









