.about-us-root {
    flex-grow: 1;
    width: 100vw;
    min-height: 100vh;
    background-color: rgba(237, 125, 49, 0.15);
    overflow:auto;
    -ms-overflow-style: none;
    scrollbar-width: none;
}

.about-us-root::-webkit-scrollbar {
    display: none;
}
