.tagbox-root {
    flex-grow: 1;
    padding:0;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    overflow:hidden;
    flex-wrap:wrap;
    margin-bottom: 10px;
}



.tagbox-tag {
    display: inline-block;
    padding: .2em .5em .3em;
    border-radius: 2px;
    font-weight: 600;
    margin: .25em .1em;
    background: #f8f8f8;
    color: #12181b

}